export default class ExternalAnalyticsDto {
  constructor(options = {}) {
    this.id = options.id || 0;
    this.organizationId = options.organizationId || 0;
    this.externalId = options.externalId || '';
    this.displayName = options.displayName || '';
    this.replacesDefaultAnalytics = options.replacesDefaultAnalytics || false;
  }
  stringify() {
    return JSON.stringify(this);
  }
}
