import Auth0Base from './auth0Base.js';
import store from '../index.js'; // used to set the X-EffectiveOrganizationHeader directly from the redux store
import OrganizationServiceDto from '../DTOs/OrganizationService/OrganizationServiceDto.js';
import OrganizationServiceListDto from '../DTOs/OrganizationService/OrganizationServiceListDto.js';
import KeywordListDto from '../DTOs/Keyword/KeywordListDto.js';
import ApplicationUserWithRolesListDto from '../DTOs/ApplicationUser/ApplicationUserWithRolesListDto.js';
import ApplicationUserApiKeyListDto from '../DTOs/ApplicationUser/ApplicationUserApiKeyListDto.js';
import ApplicationUserApiKeyDto from '../DTOs/ApplicationUser/ApplicationUserApiKeyDto.js';
import DemographicSchemasDto from '../DTOs/Organization/DemographicSchemasDto.js';
import StaffDto from '../DTOs/Staff/StaffDto.js';
import StaffListDto from '../DTOs/Staff/StaffListDto.js';
import ClientDto from '../DTOs/Client/ClientDto.js';
import ApplicationUserClientListDto from '../DTOs/Client/ApplicationUserClientListDto.js';
import ClientCategoriesDto from '../DTOs/Client/ClientCategoriesDto.js';
import ProgramCategoriesDto from '../DTOs/Program/ProgramCategoriesDto.js';
import HistoryDto from '../DTOs/Client/HistoryDto.js';
import MeasureHistoryDto from '../DTOs/Client/MeasureHistoryDto.js';
import ClientSearchResultDto from '../DTOs/Client/ClientSearchResultDto.js';
import StaffSearchResultDto from '../DTOs/Staff/StaffSearchResultDto.js';
import ProgramListDto from '../DTOs/Program/ProgramListDto.js';
import ProgramDto from '../DTOs/Program/ProgramDto.js';
import ServiceActivityDto from '../DTOs/ServiceActivity/ServiceActivityDto.js';
import ServiceActivityResultsDto from '../DTOs/ServiceActivity/ServiceActivityResultsDto.js';
import AssignedStaffOfManagerDto from '../DTOs/Staff/AssignedStaffOfManagerDto.js';
import ProgramSearchResultDto from '../DTOs/Program/ProgramSearchResultDto.js';
import MeasureListDto from '../DTOs/Measure/MeasureListDto.js';
import MeasureDto from '../DTOs/Measure/MeasureDto.js';
import MeasureSearchResultDto from '../DTOs/Measure/MeasureSearchResultDto.js';
import OutcomeSummaryDto from '../DTOs/Dashboard/OutcomeSummaryDto.js';
import DashboardCountDto from '../DTOs/Dashboard/DashboardCountDto.js';
import ServiceHoursDto from '../DTOs/Dashboard/ServiceHoursDto.js';
import SnowflakeLastRunDto from '../DTOs/Dashboard/SnowflakeLastRunDto.js';
import UserInfoDto from '../DTOs/Store/UserInfoDto.js';
import OrganizationDto from '../DTOs/Organization/OrganizationDto.js';
import OrganizationInvalidDataListDto from '../DTOs/OrganizationInvalidData/OrganizationInvalidDataListDto.js';
import OrganizationInvalidDataDto from '../DTOs/OrganizationInvalidData/OrganizationInvalidDataDto.js';
import OrganizationListDto from '../DTOs/Organization/OrganizationListDto.js';
import download from 'downloadjs';
import ImportResultsDto from '../DTOs/shared/ImportResultsDto.js';
import ApiFilterListDto from '../DTOs/ApiFilter/ApiFilterListDto.js';
import ApiFilterDto from '../DTOs/ApiFilter/ApiFilterDto.js';
import EditMeasureDataDto from '../DTOs/Measure/EditMeasureDataDto.js';
import ClientMeasureDataDto from '../DTOs/Measure/ClientMeasureDataDto.js';
import ClientMeasureResultsDto from '../DTOs/Measure/ClientMeasureResultsDto.js';
import ProgramMeasureDataDto from '../DTOs/Measure/ProgramMeasureDataDto.js';
import ImportFieldResultsDto from '../DTOs/Import/ImportFieldResultsDto.js';
import SponsoringOrganizationListDto from '../DTOs/Organization/SponsoringOrganizationListDto.js';
import OrganizationSearchResultDto from '../DTOs/Organization/OrganizationSearchResultDto.js';
import ClientIndexListDto from '../DTOs/Client/ClientIndexListDto.js';
import ServiceHoursDetailsListDto from '../DTOs/OrganizationService/ServiceHoursDetailsListDto.js';
import CostPerSuccessListDto from '../DTOs/Program/CostPerSuccessListDto.js';
import ProgramClientListDto from '../DTOs/ProgramClient/ProgramClientListDto.js';
import BackgroundJobDto from '../DTOs/BackgroundJob/BackgroundJobDto.js';
import { MeasureInclusionTypes } from '../constants.js';
import { cacheNeedsUpdate } from './localStorageHelper.js'
import ClientFilesListDto from '../DTOs/ClientFiles/ClientFilesListDto.js';
import ClientEventLogDto from '../DTOs/Client/ClientEventLogDto.js';

const API_URL = process.env.REACT_APP_API_URL + 'api/v1';

export const getEffectiveOrgId = () => {
  const state = store.getState();
  return state.user.effectiveOrganizationId;
};

const setHeaders = async function () {
  const headers = await Auth0Base.getHeaders();
  const effectiveOrganizationId = getEffectiveOrgId();

  if (isNaN(effectiveOrganizationId)) {
    return headers;
  } else {
    headers.append('X-EffectiveOrganizationId', effectiveOrganizationId);
    return headers;
  }
};

const downloadResponse = async function (response) {
  // Get the file name
  const disposition = response.headers.get('Content-Disposition');

  if (disposition === undefined)
    throw new Error({ message: 'Content-Disposition header not found; Invalid file download.', response: response });

  const matchkvp = /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/i.exec(disposition)[0]; // extract filename="SomeFileName.ext"
  const withinQuotes = matchkvp.split('=')[1];  // grab after the =

  let filename = withinQuotes;

  if (withinQuotes.substr(0, 1) === '"')
    filename = withinQuotes.substring(1, withinQuotes.length - 1);  // strip quotes

  // Get the file data and download the file
  const responseData = await response.blob();
  download(responseData, filename);

  return responseData;
}
/**
 * Private helper method for sending a request to the API.
 * 
 * @private
 * @param {String} url - the url endpoint
 * @param {Object} requestOptions - Any other options to send with the request. (ex. body)
 * 
 * @returns {Promise<Response>} A promise object which resolves with the response of the request.
 */
const send = async function (url, requestOptions) {
  const headers = await setHeaders();
  return fetch(url, { headers, ...requestOptions })
    .then(response => {

      if (response.status >= 400 && response.status < 600) {

        switch (response.status) {
          case 400:
          case 401:
          case 404:
          case 500:
            return response.json().then(err => {
              throw new Error(`${err.message ? err.message : JSON.stringify(err)}`);
            });
          default:
            throw new Error(`Http ${response.status}`);
        }
      }
      return response;
    })
    .catch(err => {
      console.log(`Error attempting to call api with url ${url}: ${err}`);
      throw err;
    });
}


/**
 * Makes a request to the API and returns the data as a JSON object (Same as calling response.json())
 * 
 * @private
 * @param {String} url - The relative url
 * @param {Object} requestOptions - Any request options optional
 * @returns {Promise<Object>} - The object returned from the api call or an empty object if the api call returns nothing
 */
export const apiCall = (url, requestOptions) => {
  const fullUrl = `${API_URL}/${url}`;
  return send(fullUrl, requestOptions)
    .then(
      response => {
        const localVersion = process.env.REACT_APP_BUILD;
        const apiVersion = response.headers.get('X-Application-Version');

        if (localVersion !== apiVersion) {
          cacheNeedsUpdate(true);
        }

        return response.text().then(text => text.length > 0 ? JSON.parse(text) : {});
      });
}

/**
 * Makes a request to the Web server for getting a static file.
 * 
 * @private
 * @param {String} url - The relative url
 * @param {String} requestOptions - Any requestion options (optional)
 * @returns {Promise<Object>} - The text returned from the static file.
 */
const webCall = (url, requestOptions) => {
  return send(url, requestOptions).then(response => response.text());
}

const uploadImportFile = async function(url, file) {
  const formData = new FormData();
  formData.append(`file`, file, file.name);

  var auth = await Auth0Base.getAuth0Client();
  var token = await auth.getTokenSilently();

  var headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);
  let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  headers.append('TimezoneUtc', timeZone);

  const effectiveOrganizationId = getEffectiveOrgId();

  if (!isNaN(effectiveOrganizationId)) {
    headers.append('X-EffectiveOrganizationId', effectiveOrganizationId);
  }

  const options = {
    headers: headers,
    method: 'POST',
    body: formData,
  };

  let response = await apiCall(url, options);
  var dto = new ImportResultsDto(response);
  return dto;
}

/**
 * Makes a request to the API to an endpoint that is known will return a file for downloading.  This function
 * also initiates the download of the file through the browser.
 * 
 * @param {String} url - The relative url
 * @param {Object} requestOptions - Any request options optional
 * @returns {Promise<Blob>} - A blob object containing the file contents  (Same as calling response.blob())
 */
export const downloadFile = async (url, requestOptions) => {
  const fullUrl = `${API_URL}/${url}`;
  const response = await send(fullUrl, requestOptions);
  return await downloadResponse(response);
}

/**
 * Makes a POST request to the API to an endpoint that is known will return a file for downloading.  This function
 * also initiates the download of the file through the browser.
 * 
 * @param {String} url - The relative url
 * @param {Object} dto - Any filters to be applied to results - required
 * @returns {Promise<Blob>} - A blob object containing the file contents  (Same as calling response.blob())
 */
export const downloadFilteredFile = async (url, dto) => {
  const fullUrl = `${API_URL}/${url}`;
  let response = await send(fullUrl, { method: 'POST', body: dto });
  return await downloadResponse(response);
}

/**
 * Utility function that will convert an object into a string for query param purposes
 * @param {Object} params - The filters object 
 * @returns - the query params appended to the request URL
 */
export function objectToQueryString(params) {
  return Object.keys(params)
    // Filters out keys where the value is undefined or null to avoid sending empty parameters.
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
}

/*
 * Object containing functions for requesting and submitting data to the API
 */
export default {
  /**
   * 
   */

  async getCurrentUser() {
    const url = `authorization/current`;
    let response = await apiCall(url);
    return new UserInfoDto(response.result);
  },

  async getDemographicSchemas() {
    const url = `organization/schemas`;
    let response = await apiCall(url);
    return new DemographicSchemasDto(response.result);
  },

  //********* Program Measure Data Import **************
  async uploadMeasureImport(file) {
    const url = `measuredata/import`;

    const formData = new FormData();
    formData.append(`file`, file, file.name);

    var auth = await Auth0Base.getAuth0Client();
    var token = await auth.getTokenSilently();

    var headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const effectiveOrganizationId = getEffectiveOrgId();

    if (!isNaN(effectiveOrganizationId)) {
      headers.append('X-EffectiveOrganizationId', effectiveOrganizationId);
    }

    const options = {
      headers: headers,
      method: 'POST',
      body: formData,
    };

    let response = await apiCall(url, options);
    var dto = new ImportResultsDto(response);
    return dto;
  },
  //****************************************************

  //********** organization service api calls **********
  async getOrganizationServiceById(id) {
    const url = `organizationservice/${id}`;
    let response = await apiCall(url);
    return new OrganizationServiceDto(response.result);
  },

  async getAllActiveServicesForOrganization() {
    const url = 'organizationservice/active';
    let response = await apiCall(url);
    return new OrganizationServiceListDto(response.result);
  },

  async getAllInactiveServicesForOrganization() {
    const url = 'organizationservice/inactive';
    let response = await apiCall(url);
    return new OrganizationServiceListDto(response.result);
  },

  async getActiveServicesForOrganization() {
    const url = 'organizationservice/active';
    let response = await apiCall(url);
    return new OrganizationServiceListDto(response.result);
  },

  async searchServices(searchTerm, pageNum, pageSize) {
    const url = `organizationservice/search/${searchTerm ? searchTerm : ''}?pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new OrganizationSearchResultDto(response.result);
  },

  async addService(dto) {
    const url = 'organizationservice/addservice';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new OrganizationServiceDto(response.result);
  },

  async updateService(dto) {
    const url = 'organizationservice/updateservice';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new OrganizationServiceDto(response.result);
  },

  async getSponsoredOrganizations(serviceId, orgId) {
    const url = `organizationservice/sponsoringorganizations/${serviceId}`;
    let response = await apiCall(url);
    return new SponsoringOrganizationListDto(response.result);
  },

  async updateSponsoredServices(dto) {
    const url = 'organizationservice/updatesponsored';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new OrganizationServiceDto(response.result);
  },

  async getAllServiceHoursDetails(dto) {
    const url = 'organizationservice/servicehoursdetails';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ServiceHoursDetailsListDto(response.result);
  },
  //****************************************************

  //**************** staff api calls *******************
  async getOrganizationInfo() {
    const url = 'applicationuser/organization';
    let response = await apiCall(url);
    return new OrganizationDto(response.result);
  },

  async getAllActiveStaff() {
    const url = 'applicationuser/active';
    let response = await apiCall(url);
    return new ApplicationUserWithRolesListDto(response.result);
  },

  async getAllInactiveStaff() {
    const url = 'applicationuser/inactive';
    let response = await apiCall(url);
    return new ApplicationUserWithRolesListDto(response.result);
  },

  async getAssignedStaffForManager(id) {
    const url = `applicationuser/assignedstaffformanager/${id}`;
    let response = await apiCall(url);
    return new AssignedStaffOfManagerDto(response.result);
  },

  async addStaff(dto) {
    const url = 'applicationuser/adduser';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new StaffDto(response.result);
  },

  async updateStaff(dto) {
    const url = 'applicationuser/updateuser';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new StaffDto(response.result);
  },

  async getStaffById(id) {
    const url = `applicationuser/${id}`;
    let response = await apiCall(url);
    return new StaffDto(response.result);
  },

  async updateClientsForStaff(dto) {
    const url = 'applicationuser/updateclients';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new StaffDto(response.result);
  },

  async updateAssignedStaffForManager(dto) {
    const url = 'applicationuser/updateassignedstaff';
    await apiCall(url, { method: 'PUT', body: dto });
  },

  async searchStaff(searchTerm, pageIndex, pageSize) {
    const url = `applicationuser/search/${searchTerm ? searchTerm : ''}?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new StaffSearchResultDto(response.result);
  },

  async getStaffForAssignment() {
    const url = 'applicationuser/allstaff';
    let response = await apiCall(url);
    return new ApplicationUserWithRolesListDto(response.result);
  },

  async getActiveOrganizationAdminsForOrg(id) {
    const url = `applicationUser/getActiveOrganizationAdministrators/${id}`;
    let response = await apiCall(url);
    return new StaffListDto(response.result);
  },

  async addOrganizationAdministrator(dto) {
    const url = 'applicationuser/addorganizationadministrator';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new StaffDto(response.result);
  },

  async updateOrganizationAdmin(dto) {
    const url = 'applicationuser/updateorganizationadmin';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new StaffDto(response.result);
  },

  async getApiFilters() {
    const url = 'applicationuser/getfilters';
    let response = await apiCall(url);
    return new ApiFilterListDto(response.result);
  },

  //****************************************************

  //**************** keyword api calls *****************
  async getAllKeywords() {
    const url = 'keyword';
    let response = await apiCall(url);
    return new KeywordListDto(response.result);
  },

  async getKeywordsByCategory(category) {
    const url = `keyword/category/${category}`;
    let response = await apiCall(url);
    return new KeywordListDto(response.result);
  },
  //****************************************************

  //****************** client api calls ****************
  async addClient(dto) {
    const url = 'client/addclient';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ClientDto(response.result);
  },

  async updateClient(dto) {
    const url = 'client/updateclient';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new ClientDto(response.result);
  },

  async getAllClientsForUser() {
    const url = 'client';
    let response = await apiCall(url);
    return new ApplicationUserClientListDto(response.result);
  },

  async getAllActiveClientsForUser() {
    const url = 'client/active';
    let response = await apiCall(url);
    return new ClientIndexListDto(response.result);
  },

  async getAllInactiveClientsForUser() {
    const url = 'client/inactive';
    let response = await apiCall(url);
    return new ClientIndexListDto(response.result);
  },

  async getAllClientsForStaff(id) {
    const url = `client/staff/${id}`;
    let response = await apiCall(url);
    return new ClientIndexListDto(response.result);
  },

  async getClientSummary(isDisabled, pageIndex, pageSize, filters = {}) {
    const queryString = objectToQueryString(filters);

    const baseUrl = `client/summary?isdisabled=${isDisabled}&pageindex=${pageIndex}&pagesize=${pageSize}${queryString ? '&' + queryString : ''}`;

    let response = await apiCall(baseUrl);
    return response.result;
  },

  async getClientById(id) {
    const url = `client/${id}`;
    let response = await apiCall(url);
    return new ClientDto(response.result);
  },

  async getClientCategoriesById(id) {
    const url = `client/categories/${id}`;
    let response = await apiCall(url);
    return new ClientCategoriesDto(response.result);
  },

  async getClientActivityHistory(clientId, serviceId, pageNum, pageSize) {
    const url = `client/activityhistory?clientId=${clientId}&serviceId=${serviceId}&pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new HistoryDto(response.result);
  },

  async getClientMeasureHistory(clientId, measureId, pageNum, pageSize) {
    const url = `client/measurehistory?clientId=${clientId}&measureId=${measureId}&pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new MeasureHistoryDto(response.result);
  },

  async getClientEventLog(clientId, pageIndex, pageSize, filters = {}) {
    const queryString = objectToQueryString(filters);

    const url =
      `client/history/?clientId=${clientId}&pageindex=${pageIndex}&pagesize=${pageSize}${queryString ? '&' + queryString : ''}`;

    let response = await apiCall(url);
    return new ClientEventLogDto(response.result);
  },

  async searchClients(searchTerm, pageNum, pageSize) {
    const url = `client/search/${searchTerm ? searchTerm : ''}?pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new ClientSearchResultDto(response.result);
  },

  async searchAllClients(searchTerm, allowDisabled, pageNum, pageSize) {
    const url = `client/search/all/${searchTerm ? searchTerm : ''}?pageNum=${pageNum}&pageSize=${pageSize}&allowDisabled=${allowDisabled}`;
    let response = await apiCall(url);
    return new ClientSearchResultDto(response.result);
  },

  async getOrganizationDemographicsSchema(url) {
    const response = await webCall(url);
    try {
      return JSON.parse(response);
    } catch (err) {
      throw new Error('Demographics schema file malformed or file does not exist');
    }
  },

  async getActiveMeasuresForClient(id) {
    const url = `client/measures/${id}`;
    let response = await apiCall(url);
    return new MeasureListDto(response.result);
  },

  async uploadDemographicImport(file) {
    const url = `client/importdemographic`;

    const formData = new FormData();
    formData.append(`file`, file, file.name);

    var auth = await Auth0Base.getAuth0Client();
    var token = await auth.getTokenSilently();

    var headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);

    const effectiveOrganizationId = getEffectiveOrgId();

    if (!isNaN(effectiveOrganizationId)) {
      headers.append('X-EffectiveOrganizationId', effectiveOrganizationId);
    }

    const options = {
      headers: headers,
      method: 'POST',
      body: formData,
    };

    let response = await apiCall(url, options);
    var dto = new ImportFieldResultsDto(response);
    return dto;
  },

  async processDemographicImport(dto) {
    const url = `client/processdemographic`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ImportResultsDto(response.result);
  },

  async deleteClientAsync(id) {
    const url = `client/delete/${id}`;
    let response = await apiCall(url, { method: 'DELETE'});
    return response;
  },
  
  //****************************************************

  //**************** program api calls *****************
  async getAllPrograms() {
    const url = 'program';
    let response = await apiCall(url);
    return new ProgramListDto(response.result);
  },

  async getProgramById(id) {
    const url = `program/${id}`;
    let response = await apiCall(url);
    return new ProgramDto(response.result);
  },

  async addProgram(dto) {
    const url = 'program/add';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ProgramDto(response.result);
  },

  async updateProgram(dto) {
    const url = 'program/update';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new ProgramDto(response.result);
  },

  async searchPrograms(searchTerm, pageIndex, pageSize) {
    const url = `program/search/${searchTerm ? searchTerm : ''}?pageIndex=${pageIndex}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new ProgramSearchResultDto(response.result);
  },

  async updateProgramMeasures(dto) {
    const url = `program/updatemeasures`;
    await apiCall(url, { method: 'PUT', body: dto });
  },

  async getClientsForProgram(id) {
    const url = `program/clients/${id}`;
    let response = await apiCall(url);
    return new ApplicationUserClientListDto(response.result);
  },

  async getProgramFiltersByOrganizations(collaborativeOrganizationId, organizationIds) {
    const url = `program/organizations`;
    let response = await apiCall(url, { method: 'POST', body: JSON.stringify({ "collaborativeOrganizationId": collaborativeOrganizationId, "organizationIds": organizationIds }) });
    return new ApiFilterDto(response.result);
  },

  async getProgramCategoriesById(id) {
    const url = `program/categories/${id}`;
    let response = await apiCall(url);
    return new ProgramCategoriesDto(response.result);
  },

  async getProgramMeasureHistory(programId, measureId, pageNum, pageSize) {
    const url = `program/measurehistory?programId=${programId}&measureId=${measureId}&pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new MeasureHistoryDto(response.result);
  },

  async getCostPerSuccess(dto) {
    const url = 'program/costpersuccess';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new CostPerSuccessListDto(response.result);
  },

  //****************************************************

  //**************** programOrganization api calls *****************

  async updateSponsoredPrograms(dto) {
    const url = 'programorganization/updatesponsored';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new ProgramDto(response.result);
  },

  async getProgramsSponsoredOrganizations(id) {
    const url = `programorganization/sponsoringorganizations/${id}`;
    let response = await apiCall(url);
    return new SponsoringOrganizationListDto(response.result);
  },

  //****************************************************

  //**************** programClient api calls *****************

  async enrollProgramClient(dto) {
    const url = `programclient`;
    await apiCall(url, { method: 'POST', body: dto });
  },

  async updateProgramClient(dto) {
    const url = `programclient`;
    await apiCall(url, { method: 'PUT', body: dto });
  },

  async getProgramClientEnrollmentForClient(clientId, enrollmentStatus, pageIndex, pageSize, searchTerm) {
    const encodedSearchhTerm = encodeURIComponent(searchTerm ? searchTerm : '' );
    const url = `programclient?clientid=${clientId}&enrollmentstatus=${enrollmentStatus}&pageindex=${pageIndex}&pagesize=${pageSize}&searchterm=${encodedSearchhTerm}`;
    let response = await apiCall(url);
    return response.result;
  },  

  async getProgramClientEnrollmentForProgram(programId, enrollmentStatus, pageIndex, pageSize, searchTerm) {
    const encodedSearchhTerm = encodeURIComponent(searchTerm ? searchTerm : '' );
    const url = `programclient?programid=${programId}&enrollmentstatus=${enrollmentStatus}&pageIndex=${pageIndex}&pageSize=${pageSize}&searchterm=${encodedSearchhTerm}`;
    let response = await apiCall(url);
    return response.result;
  },

  async getAvailableClientsForEnrollment(programId, pageIndex, pageSize, searchTerm) {
    const encodedSearchhTerm = encodeURIComponent(searchTerm ? searchTerm : '' );
    const url = `programclient/availableclients?programid=${programId}&pageIndex=${pageIndex}&pageSize=${pageSize}&searchterm=${encodedSearchhTerm}`;
    let response = await apiCall(url);
    return response.result;
  },

  async getAvailableProgramsForEnrollment(clientId, pageIndex, pageSize, searchTerm) {
    const encodedSearchhTerm = encodeURIComponent(searchTerm ? searchTerm : '' );
    const url = `programclient/availableprograms?clientid=${clientId}&pageIndex=${pageIndex}&pageSize=${pageSize}&searchterm=${encodedSearchhTerm}`;
    let response = await apiCall(url);
    return response.result;
  },

  async deleteProgramClient(id) {
    const url = `programclient/${id}`
    let response = await apiCall(url, { method: 'DELETE'});
    return response;
  },

  //****************************************************

  //********** service activity api calls **************
  async getServiceActivityById(id) {
    const url = `serviceactivity/${id}`;
    let response = await apiCall(url);
    return new ServiceActivityDto(response.result);
  },

  async addServiceActivity(dto) {
    const url = 'serviceactivity/add';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ServiceActivityResultsDto(response.result);
  },

  async updateServiceActivity(dto) {
    const url = 'serviceactivity/update';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new ServiceActivityDto(response.result);
  },

  async deleteServiceActivity(id) {
    const url =`serviceactivity/delete/${id}`;
    let response = await apiCall(url, { method: 'DELETE'});
    return response;
  },
  
  //****************************************************

  //*************** measure api calls ******************
  async getAllMeasures() {
    const url = 'measure';
    let response = await apiCall(url);
    return new MeasureListDto(response.result);
  },

  async getDraftedMeasures() {
    const url = 'measure/drafted';
    let response = await apiCall(url);
    return new MeasureListDto(response.result);
  },

  async searchMeasures(searchTerm = "", pageIndex = 0, pageSize = 50,
    measureInclusionType = MeasureInclusionTypes.NONE, includeDraft = false) {

    const encodedSearchTerm = encodeURIComponent(searchTerm ? searchTerm : '');
    
    const url = `measure/search/${encodedSearchTerm }` +
      `?pageIndex=${pageIndex}` +
      `&pageSize=${pageSize}` +
      `&includeDraft=${includeDraft}` +
      `&inclusionType=${measureInclusionType}`;

    let response = await apiCall(url);
    return new MeasureSearchResultDto(response.result);
  },

  async searchMeasuresForOrganization(searchTerm, pageNum, pageSize, includeDraft = false) {
    const encodedSearchhTerm = encodeURIComponent(searchTerm ? searchTerm : '' );
    
    const url = `measure/searchmeasures/${encodedSearchhTerm}?pageNum=${pageNum}&pageSize=${pageSize}&includeDraft=${includeDraft}`;
    let response = await apiCall(url);
    return new MeasureSearchResultDto(response.result);
  },

  async getMeasureById(id) {
    const url = `measure/${id}`;
    let response = await apiCall(url);
    return new MeasureDto(response.result);
  },

  async getMeasuresForAssignment() {
    const url = 'measure/all';
    let response = await apiCall(url);
    return new MeasureListDto(response.result);
  },

  async addMeasure(dto) {
    const url = 'measure/add';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new MeasureDto(response.result);
  },

  async updateMeasure(dto) {
    const url = 'measure/update';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new MeasureDto(response.result);
  },

  async getAllMeasuresForProgram(id) {
    const url = `measure/programmeasures/${id}`;
    let response = await apiCall(url);
    return new MeasureListDto(response.result);
  },
  //****************************************************

  //************* measure data api calls ***************
  async addClientMeasureData(dto) {
    const url = `measuredata/client/add`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ClientMeasureResultsDto(response.result);
  },

  async addProgramMeasureData(dto) {
    const url = `measuredata/program/add`;
    await apiCall(url, { method: 'POST', body: dto });
  },

  async updateClientMeasureData(dto) {
    const url = `measuredata/client/update`;
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new ClientMeasureDataDto(response.result);
  },

  async updateProgramMeasureData(dto) {
    const url = `measuredata/program/update`;
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new ProgramMeasureDataDto(response.result);
  },

  async getClientMeasureDataById(id) {
    const url = `measuredata/client/${id}`;
    let response = await apiCall(url);
    return new EditMeasureDataDto(response.result);
  },

  async getProgramMeasureDataById(id) {
    const url = `measuredata/program/${id}`;
    let response = await apiCall(url);
    return new EditMeasureDataDto(response.result);
  },

  async deleteClientMeasureData(id) {
    const url = `measuredata/client/delete/${id}`;
    let response = await apiCall(url, { method: 'DELETE' });
    return response;
  },

  async deleteProgramMeasureData(id) {
    const url = `measuredata/program/delete/${id}`;
    let response = await apiCall(url, { method: 'DELETE' });
    return response;
  },
  //****************************************************

  //************* dashboard api calls ******************
  async getDashboardOrganizations(collaborativeOrganizationId) {
    const url = `dashboard/organizations?collaborativeOrganizationId=${collaborativeOrganizationId}`;
    let response = await apiCall(url);
    return new ApiFilterDto(response.result);
  },

  async getOutcomeSummaries(dto) {
    const url = `dashboard/outcomesummaries`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new OutcomeSummaryDto(response.result);
  },

  async getServiceHours(dto) {
    const url = `dashboard/servicehours`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new ServiceHoursDto(response.result);
  },

  async getUniqueClientCount(dto) {
    const url = `dashboard/uniqueClientCount`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new DashboardCountDto(response.result);
  },

  async getProgramCount(dto) {
    const url = 'dashboard/programCount';
      let response = await apiCall(url, { method: 'POST', body: dto });
      return new DashboardCountDto(response.result);
  },

  async getProgramsWithEnrollmentCount(dto) {
      const url = 'dashboard/programsWithEnrollment';
      let response = await apiCall(url, { method: 'POST', body: dto });
      return new DashboardCountDto(response.result);
  },

  async getUniqueClientsEnrolledCount(dto) {
    const url = 'dashboard/uniqueClientsEnrolledCount';
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new DashboardCountDto(response.result);
  },

  async getUniqueClientsServedCount(dto) {
    const url = `dashboard/uniqueClientsServedCount`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new DashboardCountDto(response.result);
  },

  async getLastDashboardUpdate() {
    const url = 'dashboard/LastRun';
    let response = await apiCall(url);
    return new SnowflakeLastRunDto(response.result);
  },
  //****************************************************

  //********* measure override api calls ***************
  async addMeasureOverride(dto) {
    const url = `measureoverride/add`;
    await apiCall(url, { method: 'POST', body: dto });
  },

  async updateMeasureOverride(dto) {
    const url = `measureoverride/update`;
    await apiCall(url, { method: 'PUT', body: dto });
  },

  async deleteMeasureOverride(id) {
    const url = `measureoverride/delete/${id}`;
    await apiCall(url, { method: 'DELETE' });
  },
  //****************************************************

  //********* demographics builder api calls ***********
  async updateDraftDemographicsSchema(dto) {
    const url = `organization/updateschema/draft`;
    await apiCall(url, { method: 'PUT', body: dto });
  },

  async publishDemographicsSchema(dto) {
    const url = `organization/updateschema/active`;
    await apiCall(url, { method: 'PUT', body: dto });
  },
  //****************************************************

  //********* organization api calls ***********
  async getAllOrganizations() {
    const url = `organization`;
    let response = await apiCall(url);
    return new OrganizationListDto(response.result);
  },

  async getOrganizationById(id) {
    const url = `organization/${id}`;
    let response = await apiCall(url);
    return new OrganizationDto(response.result);
  },

  async addOrganization(dto) {
    const url = `organization/add`;
    let response = await apiCall(url, { method: 'POST', body: dto });
    return new OrganizationDto(response.result);
  },

  async updateOrganization(dto) {
    const url = 'organization/updateOrganization';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new OrganizationDto(response.result);
  },

  async searchSponsored(searchTerm, pageNum, pageSize) {
    const url = `organization/searchsponsored/${searchTerm ? searchTerm : ''}?pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new OrganizationSearchResultDto(response.result);
  },

  async getCollaborative(id) {
    const url = `organization/getcollaborative/${id}`;
    let response = await apiCall(url);
    return new OrganizationListDto(response.result);
  },

  async searchAllOrganizations(searchTerm, pageNum, pageSize) {
    const url = `organization/search/${searchTerm ? searchTerm : ''}?pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new OrganizationSearchResultDto(response.result);
  },

  async getActivelySponsoredOrganizations(id) {
    const url = `organization/activelysponsoredby/${id}`;
    let response = await apiCall(url);
    return new OrganizationListDto(response.result);
  },

  async updateSponsoredOrganizations(dto) {
    const url = 'organization/updatesponsoredorganizations';
    let response = await apiCall(url, { method: 'PUT', body: dto });
    return new OrganizationListDto(response.result);
  },

  async getSponsoringOrganizationsAsync(searchTerm, pageNum, pageSize) {
    const url = `organization/sponsoring/${searchTerm ? searchTerm : ''}?pageNum=${pageNum}&pageSize=${pageSize}`;
    let response = await apiCall(url);
    return new OrganizationSearchResultDto(response.result);
  },
  //****************************************************

  //********* API key api calls ***********

  async createApiKeyForCurrentUser() {
    const url = `applicationuserapikey/createforcurrentuser`;
    let response = await apiCall(url, { method: 'POST'});
    return new ApplicationUserApiKeyDto(response.result);
  },

  async getApiKeysForCurrentUser() {
    const url = `applicationuserapikey/getallforcurrentuser`;
    let response = await apiCall(url);
    return new ApplicationUserApiKeyListDto(response.result);
  },

  //****************************************************

  //********* Import api calls ***********

  async downloadServiceActivityTemplate() {
    downloadFile(`import/serviceactivity`, {});
  },

  async uploadServiceImport(file) {
    const url = `import/serviceactivity`;
    return uploadImportFile(url, file);
  },

  async downloadClientEnrollmentTemplate() {
    downloadFile('import/enrollment', {});
  },

  async uploadClientEnrollmentImport(file) {
    const url = `import/enrollment`;
    return uploadImportFile(url, file);
  },

  async downloadClientUnenrollmentTemplate() {
    downloadFile('import/unenrollment', {});
  },

  async uploadClientUnenrollmentImport(file) {
    const url = `import/unenrollment`;
    return uploadImportFile(url, file);
  },

//****************************************************

//********* Support api calls ***********

  async setMeasureToDraft(id) {
    const url = `support/setMeasureToDraft/${id}`;
    let response = await apiCall(url, { method: 'POST' });
    return new MeasureDto(response.result);
},


  //****************************************************

//********* Data Integrity api calls ***********

  async getInvalidOrganizationData() {
    const url = `dataIntegrity/OrganizationInvalidData/`;
    let response = await apiCall(url, { method: 'GET' });
    return new OrganizationInvalidDataListDto(response.result);
  },

  async getInvalidOrganizationDataRecord(id) {
    const url = `dataIntegrity/OrganizationInvalidData/${id}`;
    let response = await apiCall(url, { method: 'GET' });
    return new OrganizationInvalidDataDto(response.result);
  },

  async updateInvalidOrganizationData(dto) {
    const url = `dataIntegrity/OrganizationInvalidData/`;
    let response = await apiCall(url, { method: 'PUT', body: JSON.stringify(dto) });
    return new OrganizationInvalidDataDto(response.result);
  },

  async scanAllMeasuresForInvalidData() {
    const url = `dataIntegrity/ScanAllMeasuresForInvalidData/`;
    apiCall(url, { method: 'POST' });
  },

  async getCurrentInvalidDataScan() {
    const url = `dataIntegrity/CurrentScan/`;
    let response = await apiCall(url, { method: 'GET' });
    return new BackgroundJobDto(response.result);
  },

  //****************************************************
}
